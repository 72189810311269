@charset "utf-8";

$base-font-family: Merriweather, Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  0.875rem;
$base-line-height: 1.5;

$text-color:   #333;
// $link-color:   #0074d9;
$link-color:   #1977c9;
$meta-color:   #a0a0a0;
$border-color: #ddd;

@import url(https://fonts.googleapis.com/css?family=Merriweather:400);
@import "color";
@import "base";
@import "layout";
@import "syntax";
@import "custom";
  
