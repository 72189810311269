@charset "utf-8";

/* Header overrides
-------------------------------------------------- */

h1 {
    font-size: 1.75rem;
    border-bottom: 1px solid $border-color;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.1rem;
}

h6 {
    font-size: 1rem;
}

.site-title {
    font-size: 2rem;
    border-bottom: none;
}

.post-header {
    border-bottom: none;
}

/* Sticky footer styles
-------------------------------------------------- */

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 60px;
}

.footer {
  position: absolute;
  bottom: 0;
  max-width: 48rem;
  height: 60px;
}

.foot {
  padding-right: 2rem;
}

/* Bibliography style
-------------------------------------------------- */

ol.bibliography {
  padding-left: 0;
}

ol.bibliography li {
  list-style-type: none;
  margin-left: 2em;
  text-indent: -2em;
  margin-bottom: 1em;
}

/* Main image 
-------------------------------------------------- */

.centerpic {
  text-align: center;
}

img.centerpic {
  border-radius: 10px;
  float:left;
  margin-right: 2rem;
}

/* Linking hovering
-------------------------------------------------- */

a:link, a.iconlink:link {
    // color: $text-color;
    color: $link-color;
    > i.fa {
	color: $text-color;
    }
}

li ul.posts li a:link {
    color: $text-color;
}

a:visited, a.iconlink:visited {
    color: $link-color;
    > i.fa {
	color: $text-color;
    }
}

ul.posts li a:visited {
    color: $text-color;
}

a:hover, a.iconlink:hover, ul.posts li a:hover {
    color: $link-color;
    > i.fa {
	color: $link-color;
    }
}

a:active, a.iconlink:active, ul.posts li a:active {
    color: $link-color;
    > i.fa {
	color: $link-color;
    }
}

/* Ignore markdown images that don't link
-------------------------------------------------- */

img[src$="man/figures/logo.png"] {
    display: none;
}

